$(function() {
  $(".u-search").click(function() {
    //u-searchクラスのついたinputタグのvalueを取得
    const searchWord = $(".u-search__word").val();
    if (searchWord) {
      //扱うクエリパラメータ
      const key = "word";

      //クエリパラメータを取り出す
      const query = window.location.href.match(
        new RegExp("[?&]" + key + "=(.*?)(&|$|#)")
      );

      //クエリパラメータを含めないURLの取得
      const url = window.location.href.replace(/\?.*$/, "");

      //クエリパラメータが重複しないようにリンクを設定
      if (query) {
        location.href = url + "?word=" + searchWord;
      } else {
        location.href = location.href + "?word=" + searchWord;
      }
    }
  });
});
