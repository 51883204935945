$(function() {
  $(".u-menu__btn").click(function() {
    $(this).toggleClass("u-menu--active");

    if ($(this).hasClass("u-menu--active")) {
      $(".u-menu").addClass("u-menu--active");
    } else {
      $(".u-menu").removeClass("u-menu--active");
    }
  });
});
