$(function() {
  $("ul.u-dropnav > li").hover(
    function() {
      if ($("ul.u-dropnav__child", this).hasClass("l-flex")) {
        $("ul.u-dropnav__child:not(:animated)", this).slideDown({
          start: function() {
            $(this).css({
              display: "flex"
            });
          }
        });
      } else {
        $("ul.u-dropnav__child:not(:animated)", this).slideDown();
      }
    },
    function() {
      $("ul.u-dropnav__child", this).slideUp();
    }
  );
});
