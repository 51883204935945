// フッター送信ボタンクリック
$(function() {
  $("#send-btn").click(function(e) {
    var mail = $("#mail-address").val();
    var lineFeed = "\r\n";
    var to = "info@test.com";
    var subject = "メルマガ会員登録";
    var body = [mail, "そのまま何も入力せず送信ください。"];
    var mailto =
      "mailto:" +
      to +
      "?subject=" +
      subject +
      "&body=" +
      body.join(encodeURIComponent(lineFeed));
    window.location.href = mailto;
  });
});
