/*
 * u-accordion__tabに隣接するu-accordion__innerが開閉する
 **/

$(function() {
  $(".u-accordion__tab").click(function() {
    $(this)
      .next(".u-accordion__inner")
      .slideToggle();
  });
});
